import { connect } from 'react-redux';
import SuccesPurchase from './SuccesPurchase';
import { closeModal } from '../../store/app/actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = { closeModal };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(SuccesPurchase);
