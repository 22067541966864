import { connect } from 'react-redux';
import IncompatibleCountry from './IncompatibleCountry';
import { closeModal } from '../../store/app/actions';

const mapStateToProps = (state) => ({
    fbUserId: state.app.userConfig.fbUserId,
});

const mapDispatchToProps = { closeModal };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IncompatibleCountry);
