// @flow
import * as React from 'react';
import {PLATFORM_GSNCOM} from '../../constants/matcher';
import SeamServices from '../../services/seamService';
import PubSub from 'pubsub-js';

type PropsT = {
};
type StateT = {};


export default class AdsVideo extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {
            ironRV: {},
            placement: '',
            isFullScreenBefore: false
        };
        this.showAd = this.showAd.bind(this);
        this.showAdGsnCom = this.showAdGsnCom.bind(this);
        this.initiateIronRv = this.initiateIronRv.bind(this);
        this.initiateIronRvInContainer = this.initiateIronRvInContainer.bind(this);
        this.initiateIronRvGsnCom = this.initiateIronRvGsnCom.bind(this);
        this.adsCommunicatorFunction = this.adsCommunicatorFunction.bind(this);

    }

    componentDidMount() {
        this.initiateIronRvInContainer();
        window.addEventListener("message", this.adsCommunicatorFunction);
    }

    initiateIronRv(appid) {
        console.log("ironrv to init", appid);
        let that = this;
        const params = {
            applicationKey		: appid,
            applicationUserId	: this.props.userData.id
        };
        const ironRV = window.IronRV && window.IronRV.getInstance(params);
        this.setState({ironRV:ironRV});
        if(ironRV) {
            ironRV.addListener(window.IronRV.EVENTS.INIT_ERROR, function() {
                console.log("Iron Rv init error");
            });
            ironRV.addListener(window.IronRV.EVENTS.READY, function() {
                console.log("Iron Rv Ready and Video Ads Available");
                PubSub.publish('VIDEO_ADS_AVAILABLE', 'true');
                that.showAd();
            });
            ironRV.addListener(window.IronRV.EVENTS.NO_CAMPAIGNS,function() {
                console.log("No Reward Video Ad Campaigns available");
            });
            ironRV.addListener(window.IronRV.EVENTS.AD_BLOCK, function() {
                console.log("Reward Add Getting Blocked From Client");
            });
            ironRV.addListener(window.IronRV.EVENTS.OPEN, function() {
                console.log("Add Video Opened");
            });
            ironRV.addListener(window.IronRV.EVENTS.CLOSE, function() {
                console.log("Add Video closed");
                let msg = {
                    RewardEventType : '6',
                    data : JSON.stringify({placement_reward_amount : 1,placement_reward_name : that.state.placement, placement_name: that.state.placement}),
                };
                msg = JSON.stringify(msg);
                if(that.state.isFullScreenBefore) {
                    const elemtoZoom = document.getElementById('gameContainer');
                    if (elemtoZoom && !document.mozFullScreen && !document.webkitFullScreen) {
                        if (elemtoZoom.mozRequestFullScreen) {
                            elemtoZoom.mozRequestFullScreen();
                        } else {
                            elemtoZoom.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
                        }
                    }
                    PubSub.publish('ESC_FULL_SCREEN','false');
                }
                console.log("video ads", msg);
                PubSub.publish('VIDEO_ADS_COMPLETED', msg);
            });
            ironRV.addListener(window.IronRV.EVENTS.COMPLETION, function() {
                console.log("Add Video completed");
                let msg2 = {
                    RewardEventType : '2',
                    data : 'empty',
                };
                msg2 = JSON.stringify(msg2);
                PubSub.publish('VIDEO_ADS_COMPLETED', msg2);

            });
        }
    }

    initiateIronRvGsnCom(appid) {
        // window.removeEventListener('message', this.adsCommunicatorFunction);
        // window.addEventListener("message", this.adsCommunicatorFunction);
        let userid = "123456789";
        if(this.props.userData.id) {
            userid = this.props.userData.id;
        }
        else if(this.props.userData.authResponse && this.props.userData.authResponse.seedUserId){
            userid = this.props.userData.authResponse.seedUserId;
        }
        else {

        }
        const messageToSend = {
            type : 'info',
            msg : {
                data: "videoads",
                action: "initiateAds",
                appid : appid,
                seamUserId: userid
            }
        };
        window.parent.postMessage(JSON.stringify(messageToSend), '*');
        console.log('Initiate Ironrv in gsncom called');
    }

    initiateIronRvInContainer(){
        PubSub.subscribe('initializeIronSource', (target, msg) => {
            if(this.props.platform === PLATFORM_GSNCOM) {
                this.initiateIronRvGsnCom(msg);
            }else {
                this.initiateIronRv(msg);
            }
        });
    }

    showAdGsnCom() {
        PubSub.subscribe('PLAY_VIDEO_ADS', (target, msg) => {
            this.setState({placement: msg});
            if (msg) {
                if (
                    document.fullscreenElement ||
                    document.webkitFullscreenElement ||
                    document.mozFullScreenElement
                ) {
                    this.setState({isFullScreenBefore: true});
                    this.props.exitFullScreen();
                    PubSub.publish('ESC_FULL_SCREEN','false');
                }
                let msg2 = {
                    RewardEventType : '3',
                    data : 'empty',
                };
                msg2 = JSON.stringify(msg2);
                PubSub.publish('VIDEO_ADS_COMPLETED', msg2);
                if(this.props.browserName == 'Safari' && this.state.isFullScreenBefore) {
                    setTimeout(() => {
                        const messageToSend = {
                            type : 'info',
                            msg : {
                                data: "videoads",
                                action: "showads",
                            }
                        };
                        window.parent.postMessage(JSON.stringify(messageToSend), '*');
                        console.log('send call show ads to gsncom');
                    },1000);
                }
                else {
                    const messageToSend = {
                        type : 'info',
                        msg : {
                            data: "videoads",
                            action: "showads",
                        }
                    };
                    window.parent.postMessage(JSON.stringify(messageToSend), '*');
                    console.log('send call show ads to gsncom in other browser');
                }
            }
        });
    }

    showAd() {
        PubSub.subscribe('PLAY_VIDEO_ADS', (target, msg) => {
            this.setState({placement: msg});
            if (msg) {
                if (
                    document.fullscreenElement ||
                    document.webkitFullscreenElement ||
                    document.mozFullScreenElement
                ) {
                    this.setState({isFullScreenBefore: true});
                    this.props.exitFullScreen();
                    PubSub.publish('ESC_FULL_SCREEN','false');
                }
                let msg2 = {
                    RewardEventType : '3',
                    data : 'empty',
                };
                msg2 = JSON.stringify(msg2);
                PubSub.publish('VIDEO_ADS_COMPLETED', msg2);
                if(this.props.browserName == 'Safari' && this.state.isFullScreenBefore) {
                    setTimeout(() => {
                        this.state.ironRV.show();
                    },1000);
                }
                else {
                    this.state.ironRV.show();
                }
            }
        });
    }

    adsCommunicatorFunction() {
        console.log('videoads event called in react', event);
        let that = this;
        if(event.data) {
            let dataFromEvent = event.data;
            if(dataFromEvent && dataFromEvent !== undefined) {
                if(dataFromEvent.type=='videoads'){
                    const actionToDO = dataFromEvent.msg.action;
                    switch (actionToDO){
                        case 'ready':
                            console.log('Video ads available');
                            PubSub.publish('VIDEO_ADS_AVAILABLE', 'true');
                            this.showAdGsnCom();
                            break;
                        case 'close':
                            console.log('Video ads closed');
                            let msg = {
                                RewardEventType : '6',
                                data : JSON.stringify({placement_reward_amount : 1,placement_reward_name : that.state.placement, placement_name: that.state.placement}),
                            };
                            msg = JSON.stringify(msg);
                            if(that.state.isFullScreenBefore) {
                                const elemtoZoom = document.getElementById('gameContainer');
                                if (elemtoZoom && !document.mozFullScreen && !document.webkitFullScreen) {
                                    if (elemtoZoom.mozRequestFullScreen) {
                                        elemtoZoom.mozRequestFullScreen();
                                    } else {
                                        elemtoZoom.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
                                    }
                                }
                                PubSub.publish('ESC_FULL_SCREEN','false');
                            }
                            console.log("video ads", msg);
                            PubSub.publish('VIDEO_ADS_COMPLETED', msg);
                            break;
                        case 'complete':
                            console.log('Video ads completed');
                            console.log("Reward Video Add completed");
                            let msg2 = {
                                RewardEventType : '2',
                                data : 'empty'
                            };
                            msg2 = JSON.stringify(msg2);
                            PubSub.publish('VIDEO_ADS_COMPLETED', msg2);
                            break;
                        case 'refreshTokens':
                            console.log('refreshTokens');
                            PubSub.publish('ADD_TOKENS_METHOD', 'true');
                            break;
                    }
                }
            }
        }

    }

    componentWillUnmount() {
        PubSub.unsubscribe('VIDEO_ADS_AVAILABLE');
        PubSub.unsubscribe('PLAY_VIDEO_ADS');
        PubSub.unsubscribe('VIDEO_ADS_COMPLETED');
        PubSub.unsubscribe('initializeIronSource');
        window.removeEventListener('message', this.adsCommunicatorFunction);
    }

    render() {
        return (
            <div id='mesmo-gl-ads-video'></div>
        );
    }
}
