import { connect } from 'react-redux';
import GoogleVideoAds from './GoogleVideoAds';
import { updateTokenBalance } from '../../store/app/actions';
const mapStateToProps = (state) => {
    return {

    };
};

const mapDispatchToProps = {

 };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(GoogleVideoAds);
