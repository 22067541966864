// @flow
import * as React from 'react';
import GameSection from '../GameSection';
import HighLowResult from '../HighLowResult';
import NextRound from '../NextRound/NextRound';
import analyticsManagerInstance from '../../managers/analyticsManager';
import analyticslog from '../../constants/analyticslog';
import {PLATFORM_GSNCOM} from '../../constants/matcher';

type PropsT = {
    registerGameCount: func,
    noOfTimesPlayed: number,
    WebglLoaded: boolean,
    isMiniGameOver: func,
    gameIsOver: boolean,
    showNextRound: boolean,
    countWin: number,
    seamSession: string,
    gsnCredentials: any,
    fbUserId: string,
    seamTokenBalance: number,
    country: string,
    operatingSystem: string,
    platform: string,
    appSessionId: string
};
type StateT = {};
const analyticsLogs = new analyticslog();
const cardDisplayed =  process.env.AWS_URL + 'card-base.png?ver=' + process.env.IMAGE_VERSION;
const cardBack =  process.env.AWS_URL + 'card_back.png?ver=' + process.env.IMAGE_VERSION;
const h =  process.env.AWS_URL + 'heart.png?ver=' + process.env.IMAGE_VERSION;
const d =  process.env.AWS_URL + 'diamond.png?ver=' + process.env.IMAGE_VERSION;
const s =  process.env.AWS_URL + 'Spade.png?ver=' + process.env.IMAGE_VERSION;
const c =  process.env.AWS_URL + 'club.png?ver=' + process.env.IMAGE_VERSION;
const allImages = [cardDisplayed, cardBack, h, d, s, c];

const cardList = [
    { cardDisplayed: cardDisplayed },
    { cardBack: cardBack },
    { h: h },
    { d: d },
    { s: s },
    { c: c },
];

export default class HighLowGame extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {
            manageGame: true,
            gameOutput: '',
            showNextRound: false,
            imageIsReady: false,
        };
        this.checkResult = this.checkResult.bind(this);
        this.openHiLoGame = this.openHiLoGame.bind(this);
        this.openNextRound = this.openNextRound.bind(this);
        this.preLoadAssets = this.preLoadAssets.bind(this);
    }
    checkResult(v) {
        this.setState({ gameOutput: v });
        this.setState({ manageGame: false });
        // if (v === 'lose' && this.props.noOfTimesPlayed === 2 && this.props.countWin === 0) {
        //     this.props.isMiniGameOver(true);
        // }
    }

    preLoadAssets() {
        let count = 0;
        allImages.forEach((image) => {
            const img = new Image();
            img.src = image;
            img.onload = () => {
                count++;
                if (count === allImages.length) {
                    this.setState({ imageIsReady: true });
                }
            };
        });
    }
    openNextRound() {
        this.setState({ showNextRound: true });
        this.props.isMiniGameOver(false);
        let userId;
        if(this.props.platform === PLATFORM_GSNCOM){
            userId = this.props.gsnCredentials && this.props.gsnCredentials.authResponse && this.props.gsnCredentials.authResponse.userID;
        }else{//FOR FB
            userId = this.props.fbUserId;
        }
        //Analytics Start
        const lodData = {
            eventGroup: 'STARTUP',
            eventCode: 'MINIGAME',
            eventSubCode: 'RELOAD',
            sessionToken: this.props.seamSession.sessionToken,
            eventAttributes: {
                game_name: 'hi-low',
                coins_balance: this.props.seamTokenBalance,
            },
            deviceId: userId,
            country: this.props.country,
            operatingSystem: this.props.operatingSystem,
            appVersion: this.props.appVersion,
            app_session_id: this.props.appSessionId,
        };
        const payload = analyticsLogs.setAnalyticsData(lodData);
        analyticsManagerInstance.logAnalytics(payload).then(() => {});
        //Analytics End
    }
    openHiLoGame() {
        this.openNextRound();
        this.Timer1 = setTimeout(() => {
            this.setState({ showNextRound: false });
            this.setState({ manageGame: true });
        });
        this.props.registerGameCount(this.props.noOfTimesPlayed + 1);
    }
    componentDidMount() {
        this.preLoadAssets();
    }

    componentWillUnMount() {
        clearTimeout(this.Timer1);
        clearTimeout(this.Timer2);
    }
    render() {
        if (this.state.imageIsReady) {
            return (
                <div>
                    {this.state.manageGame && (
                        <GameSection gameResult={this.checkResult} imgUrlList={cardList} appVersion = {this.props.appVersion}  />
                    )}
                    {!this.state.manageGame &&
                        !this.props.gameIsOver && (
                            <HighLowResult
                                show={this.state.gameOutput}
                                openHighLowGame={this.openHiLoGame}
                                appVersion = {this.props.appVersion}
                            />
                        )}
                    {this.state.showNextRound && <NextRound />}
                </div>
            );
        }
        return <div> Loading Card...</div>;
    }
}
