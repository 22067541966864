/* eslint-disable no-undef */

'use strict';
import PubSub from 'pubsub-js';
import analyticsManagerInstance from './analyticsManager';
import {webglCommunicator} from '../managers/webglCommunicator';

export default function WebglManagerV2(webglPayload) {
    const {facebookUserObject, analyticsLogs, FBResForDevice,logEngineeringEvents,country,operatingSystem,webAppVersion,browserName, loginTimeSyncDelay, appVersionRollOut, appVersionObj } = webglPayload;
    let webglError;
    let extension = appVersionObj ? appVersionObj.extension : "";
    let loaderFileName = appVersionObj ? appVersionObj.loaderFileName : "";

    console.log("extension =>>>>", extension);
    console.log("loaderFileName =>>>>", loaderFileName);
    console.log("appVersionObj =>>>>", appVersionObj);

    window.webglClearIntervalError = function(){
        return clearInterval(webglError)
    };
    const sendLog = function (str){
        //Analytics - ENGINEERING-start
        const log = {
            eventGroup: 'ENGINEERING',
            eventCode: 'ERROR_UNITY_LOAD',
            eventSubCode: str,
            deviceId: FBResForDevice && FBResForDevice.authResponse && FBResForDevice.authResponse.userID,
            eventAttributes: {
                ui_element: 'mini_lobby',
                from_where: 'webgl_manager',
            },
            info: str,
            sessionToken: facebookUserObject && facebookUserObject.sessionToken,
            country: country,
            operatingSystem: operatingSystem,
            appVersion: appVersionRollOut,
        };
        logEngineeringEvents && analyticsManagerInstance
            .logAnalytics(analyticsLogs.setAnalyticsData(log))
            .then(() => {});
    };
    //Loading WEBGL
    let webGlUrl = process.env.UNITY_URL;
    if(appVersionRollOut){
        const unityUrl = process.env.UNITY_URL.split('WebGLBuilds/')[0];
        webGlUrl = unityUrl + 'WebGLBuilds/' + appVersionRollOut;
        console.log('check url for userID::', webGlUrl);
        console.log('check url for webAppVersion::', webAppVersion);
    }
    if (webAppVersion) {
        const unityUrl = process.env.UNITY_URL.split('WebGLBuilds/')[0];
        webGlUrl = unityUrl + 'WebGLBuilds/' + webAppVersion;
        console.log('check url for webAppVersion::', webAppVersion);
        console.log('check url for webGlUrl::', webGlUrl);
    }
    loaderFileName = (loaderFileName && loaderFileName !== "")  ? loaderFileName : process.env.UNITY_LOADER_V2;
    let loaderUrl =`${webGlUrl}/${loaderFileName}`;
    console.log('WHAT IS VERSION OF LOADER APP::', loaderUrl);

    const loaderJS = document.createElement('script');
    let gameInstance = {};
    //loaderJS.type = 'application/javascript';
    loaderJS.src = loaderUrl;
    //document.head.appendChild(loaderJS);
    document.body.appendChild(loaderJS);

    const unityShowBanner = function (msg, type) {
        let warningBanner = document.getElementById('unity-warning');

        function updateBannerVisibility() {
            if(warningBanner && warningBanner !== null) {
                warningBanner.style.display = warningBanner.children.length ? 'block' : 'none';
            }
        }
        var div = document.createElement('div');
        div.innerHTML = msg;
        if(warningBanner && warningBanner !== null) {
            warningBanner.appendChild(div);
        }
        if (type == 'error') div.style = 'background: red; padding: 10px;';
        else {
          if (type == 'warning') div.style = 'background: yellow; padding: 10px;';
          setTimeout(function() {
            if(warningBanner && warningBanner !== null) {
                warningBanner.removeChild(div);
            }
            updateBannerVisibility();
          }, 5000);
        }
        updateBannerVisibility();
      }
    //debugger;
    loaderJS.onload = function () {
        let count =0;
      //  debugger;
        HTMLCanvasElement.prototype.getContext = (function(oldFn) {
            return function(type) {
                window.webglApply  =  oldFn.apply(this, arguments);
                return  window.webglApply;
            };
        }(HTMLCanvasElement.prototype.getContext));
        //debugger;
        console.info("Unity WebGL Loader version=V2");

        let canvas = document.querySelector("#unity-canvas");
        extension = extension ? extension: "";
        let config = {
            dataUrl: `${webGlUrl}/Build/WebGL.data${extension}`,
            frameworkUrl: `${webGlUrl}/Build/WebGL.framework.js${extension}`,
            codeUrl: `${webGlUrl}/Build/WebGL.wasm${extension}`,
            symbolsUrl:`${webGlUrl}/WebGL.symbols.json${extension}`,
            streamingAssetsUrl: `StreamingAssets`,
            companyName: "Game Show Network Games",
            productName: "GSN Casino",
            productVersion: appVersionRollOut,
            showBanner: unityShowBanner,
            cacheControl: function (url) {
                // Caching enabled for .data and .bundle files.
                // Revalidate if file is up to date before loading from cache
                if (url.match(/\.data/) || url.match(/\.bundle/) || url.match(/\.unity3d/)) {
                    return "must-revalidate";
                }
                // Caching enabled for .mp4 and .custom files
                // Load file from cache without revalidation.
                if (url.match(/\.mp4/) || url.match(/\.custom/)) {
                    return "immutable";
                }
                // Disable explicit caching for all other files.
                // Note: the default browser cache may cache them anyway.
                return "no-store";
            },
        };

        createUnityInstance(canvas, config, (progress) => {
            console.log("createUnityInstance",config);
            // progressBarFull.style.width = 100 * progress + "%";
            console.log("V2 progress->",100 * progress + "%");
            if(count ===0){
                PubSub.publish('UNITY_PROGRESS_INDICATOR', progress);
                count=1;
            }
        }).then((unityInstance) => {
            //Analytics event check
            console.log("V2 unityInstance -> ",unityInstance);
            if(!unityInstance.Module){
                //Analytics - ENGINEERING-start
                const log = {
                    eventGroup: 'ENGINEERING',
                    eventCode: 'UNITY_LOADER_START_FAIL',
                    deviceId: FBResForDevice && FBResForDevice.authResponse && FBResForDevice.authResponse.userID,
                    eventAttributes: {
                        ui_element: 'mini_lobby',
                        from_where: 'unity_loader_createinstance',
                    },
                    sessionToken: facebookUserObject.sessionToken,
                    country: country,
                    operatingSystem: operatingSystem,
                    appVersion: appVersionRollOut,
                };
                logEngineeringEvents && analyticsManagerInstance
                .logAnalytics(analyticsLogs.setAnalyticsData(log))
                .then(() => {});
                //Analytics - ENGINEERING-End
                console.log("log send for UNITY_LOADER_START_FAIL=>", log);
            }else{
                window.WebGLCommunicator = webglCommunicator.callSelf(webglPayload,unityInstance, loginTimeSyncDelay, appVersionRollOut);
                //window.webglCommunicator
                // setTimeout(()=>{
                //     WebglCommunicator.requestForLoginData('CMD');
                // },3000)

                //Analytics - ENGINEERING-start
                const log = {
                    eventGroup: 'ENGINEERING',
                    eventCode: 'UNITY_LOADER_START_SUCCESS',
                    deviceId: FBResForDevice && FBResForDevice.authResponse && FBResForDevice.authResponse.userID,
                    eventAttributes: {
                        ui_element: 'mini_lobby',
                        from_where: 'unity_loader',
                    },
                    sessionToken: facebookUserObject.sessionToken,
                    country: country,
                    operatingSystem: operatingSystem,
                    appVersion: appVersionRollOut,
                };
                logEngineeringEvents && analyticsManagerInstance
                    .logAnalytics(analyticsLogs.setAnalyticsData(log))
                    .then(() => {});
                //Analytics - ENGINEERING-End

                console.log("log send for UNITY_LOADER_START_SUCCESS=>", log);
            }
            }).catch((message) => {
                //Analytics - ENGINEERING-start
                const log = {
                    eventGroup: 'ENGINEERING',
                    eventCode: 'UNITY_LOADER_START_FAIL',
                    deviceId: FBResForDevice && FBResForDevice.authResponse && FBResForDevice.authResponse.userID,
                    eventAttributes: {
                        ui_element: 'mini_lobby',
                        from_where: 'unity_loader_trycatch',
                    },
                    sessionToken: facebookUserObject.sessionToken,
                    country: country,
                    operatingSystem: operatingSystem,
                    appVersion: appVersionRollOut,
                };
                logEngineeringEvents && analyticsManagerInstance
                    .logAnalytics(analyticsLogs.setAnalyticsData(log))
                    .then(() => {});
                //Analytics - ENGINEERING-End
            console.error("log send for UNITY_LOADER_START_FAIL=>", log);
            console.error("Error UNITY_LOADER_START_FAIL=>", message);
            });
    };

    webglError = setInterval(()=>{
        const errorValue =  window.webglApply && window.webglApply.getError();
        if(parseInt(errorValue)>=0){
            switch(parseInt(errorValue)){
                case window.webglApply.INVALID_OPERATION:
                                sendLog('INVALID_OPERATION ERROR');
                                webglClearIntervalError();
                                // Due to loading issue as invalid operation error will occur in other cases as well which is not harmful.
                                // if(browserName==='Chrome'){
                                //     PubSub.publish('BLANK_UNITY_LOADER_ERROR', true);
                                // }
                                break;
                case window.webglApply.INVALID_ENUM :
                                sendLog('INVALID_ENUM ERROR');
                                webglClearIntervalError();
                                //PubSub.publish('BLANK_UNITY_LOADER_ERROR', true);
                                break;
                case window.webglApply.INVALID_VALUE :
                                sendLog('INVALID_VALUE ERROR');
                                webglClearIntervalError();
                                //PubSub.publish('BLANK_UNITY_LOADER_ERROR', true);
                                break;
                case window.webglApply.INVALID_FRAMEBUFFER_OPERATION :
                                sendLog('INVALID_FRAMEBUFFER_OPERATION ERROR');
                                webglClearIntervalError();
                                //PubSub.publish('BLANK_UNITY_LOADER_ERROR', true);
                                break;
                case window.webglApply.OUT_OF_MEMORY :
                                sendLog('OUT_OF_MEMORY ERROR');
                                webglClearIntervalError();
                                //PubSub.publish('BLANK_UNITY_LOADER_ERROR', true);
                                break;
                case window.webglApply.CONTEXT_LOST_WEBGL :
                                sendLog('CONTEXT_LOST_WEBGL ERROR');
                                webglClearIntervalError();
                                //PubSub.publish('BLANK_UNITY_LOADER_ERROR', true);
                                break;
                case window.webglApply.NO_ERROR:
                                console.info('NO ERROR from WebGL');
            }
        }
    }, 2000);

    loaderJS.onerror = function (message){
        window.TrackJS && TrackJS.track("Failed to load url "+this.src + message);
    };

    window.onerror = function (message, source, lineno, colno, error) {
        console.error('Error ', {
            message:message,
            url: source,
            line:lineno,
            column: colno,
            error:error,
        });
        window.alert = () => '';
        //Analytics - ENGINEERING-start
        const log = {
            eventGroup: 'ENGINEERING',
            eventCode: 'WINDOW_ERROR_UNITY',
            deviceId: FBResForDevice && FBResForDevice.authResponse && FBResForDevice.authResponse.userID,
            eventAttributes: {
                ui_element: 'mini_lobby',
                from_where: message,
            },
            sessionToken: facebookUserObject && facebookUserObject.sessionToken,
            country: country,
            operatingSystem: operatingSystem,
            appVersion: appVersionRollOut,
        };
        logEngineeringEvents && analyticsManagerInstance
            .logAnalytics(analyticsLogs.setAnalyticsData(log))
            .then(() => {});
        //Analytics - ENGINEERING-End
        PubSub.publish('UNITY_LOADER_ERROR', true);
    }
}

