import { connect } from 'react-redux';
import PreLobbyLoader from './PreLobbyLoader';
import { openModal, closeModal } from '../../store/app/actions';

const mapStateToProps = (state) => {
    return {
        isContainerDisplayed: state.app.userConfig.isContainerDisplayed,
        seamSession: state.app.userConfig.seamSession,
        fbUserId: state.app.userConfig.fbUserId,
        gsnCredentials: state.app.userConfig.gsnCredentials,
        platform: state.app.platform,
        appSessionId: state.app.userConfig.appSessionId
    };
};
const mapDispatchToProps = { openModal, closeModal };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(PreLobbyLoader);
