/* eslint-disable no-undef */

'use strict';
import PubSub from 'pubsub-js';
import SeamServices from '../services/seamService';
import analyticsManagerInstance from './analyticsManager';
import {PUBSUB_EVENTS_LIST, PLATFORM_FB} from '../constants/matcher';
import { set } from 'lodash';

export const webglCommunicator = (() =>{
    let unityCommunicatorObject;
    let unityCommunicatorFunctionName;
    let webglPayload = {
        facebookUserObject:{},
        analyticsLogs:{},
        FBResForDevice:{},
        logEngineeringEvents:{},
        country:'',
        operatingSystem:'',
        webAppVersion:'',
        browserName:'',
        loginTimeSyncDelay:0,
        appVersionRollOut:''
    };
    let platform;
    let gameInstance;
    let loginTimeSyncDelay;
    let appVersionRollOut;
    let count=0;
    let cmdOfPayment = '';
    let isInsideGame = false;
    let messagesent = false;

    const init = (cmd, a_unityCommunicatorObject, a_unityCommunicatorFunctionName)=>{
        console.log('Data for init ::')
        unityCommunicatorObject = a_unityCommunicatorObject;
        unityCommunicatorFunctionName = a_unityCommunicatorFunctionName;
        platform = webglPayload && webglPayload.facebookUserObject && webglPayload.facebookUserObject.platform;
        const webglMsg = { cmd:cmd, msg: platform}
        SendMessageWebGl(webglMsg);
    }

    const requestForLoginData = (cmd)=>{
        const facebookUserObject = webglPayload && webglPayload.facebookUserObject;
        if (facebookUserObject){
            facebookUserObject.loginTimeSyncDelay = new Date().getTime() - loginTimeSyncDelay;
        }
        const payload = facebookUserObject && JSON.stringify(facebookUserObject);
        const webglMsg = { cmd:cmd, msg:payload}
        console.log('Data for requestForLoginData ::', facebookUserObject);
        SendMessageWebGl(webglMsg);
    };
    const requestForSwitchToContainer =(cmd)=>{
        console.log('Data for requestForSwitchToContainer ::', cmd);
        window.webglClearIntervalError && webglClearIntervalError();
        PubSub.publish(PUBSUB_EVENTS_LIST.WEBGL_LOADED, true);
        PubSub.subscribe(PUBSUB_EVENTS_LIST.WEBGL_IS_LOADED, (target, msg) => {
            isInsideGame = true;
            console.log('WEBGL_IS_LOADED Container',JSON.stringify(msg));
            if (msg) {
                //gameInstance.SendMessage('WebGL_Canvas', 'OnDashboardEntered', JSON.stringify(msg));
                const webglMsg = { cmd:cmd, msg:JSON.stringify(msg)};
                SendMessageWebGl(webglMsg);
            }
        });

        //OnDashboardEntered OLD Use
    };

    const UpdateLoadingProgress = (progress) =>{
        console.log('Second progress ::::', progress);
        if(count === 0){
            PubSub.publish(PUBSUB_EVENTS_LIST.PROGRESS_INDICATOR, progress);
            count = 1;
        }
    }

    const updateErrorFromContainer = (errorMsg)=>{
        console.log('Data for updateErrorFromContainer ::', errorMsg);
        //CatchException
        //CatchLogExceptions
        return "gsncom";

    };
    const openURL = (url, urlType)=> {
        console.log('VALUE OF URL::', url);
        console.log('VALUE OF URL TYPE::', urlType);
        if(urlType === 'NeedHelp'){
            PubSub.publish(PUBSUB_EVENTS_LIST.OPEN_CONTACT_SUPPORT, urlType); //Contact Support Open Link
        }else if(urlType === 'sametab') {
            window.open(url, '_top');
        }else if(url.search('gsnu')>-1){
            const messageToSend = {
                type: 'redirect',
                msg: {
                    data: url,
                },
            };
            window.parent.postMessage(JSON.stringify(messageToSend), '*');
        }else{
            window.open(url, '_blank');
        }
    };

    const InitializePayment =(cmd, productId, tokenAmount, price, orderId)=>{
        const productURL = `${SeamServices.getSeamBaseURL()}/store/v2/facebook/product/${productId}/${tokenAmount}`;
        const sessionToken = webglPayload && webglPayload.facebookUserObject && webglPayload.facebookUserObject.sessionToken;
        const userID = webglPayload.FBResForDevice && webglPayload.FBResForDevice.authResponse && webglPayload.FBResForDevice.authResponse.userID;
        console.info('Initiate Payment for ', { ProductURL: productURL, OrderId: orderId });
        const obj = {
            method: 'pay',
            action: 'purchaseitem',
            product: productURL,
            request_id: orderId,
        };

        if( orderId && orderId.indexOf('-')>-1 ) {
            console.error('Order id format is not crct');
            const log = {
                eventGroup: 'ENGINEERING',
                eventCode: 'FACEBOOK_PAYMENT_ERROR_SEAM',
                deviceId: userID,
                eventAttributes: {
                    ui_element: 'mini_lobby',
                    from_where: 'facebook_payment_seam',
                },
                sessionToken: sessionToken,
                country: webglPayload.country,
                operatingSystem: webglPayload.operatingSystem,
                appVersion: appVersionRollOut,
            };
            webglPayload.logEngineeringEvents && analyticsManagerInstance
                .logAnalytics(webglPayload.analyticsLogs.setAnalyticsData(log))
                .then(() => {});
            //Analytics - ENGINEERING-End
            console.error('FACEBOOK PAYMENT ERROR SEAM', { error: "Order Id format is in correct" });
            const webglMsg = { cmd:cmd, msg: JSON.stringify({_statusCode:'500', _json:"seam failure"})};
            SendMessageWebGl(webglMsg);
        } else {
            // Scraping is required because the metadata changes for each purchase (i.e. number of tokens shown)
            FB.api(
                'https://graph.facebook.com/',
                'post',
                {
                    id: productURL,
                    scrape: true,
                },
                (response) => {
                    console.info('Rescrape for FB Payment', { message: response });
                    FB.ui(obj, (data) => {
                        console.info('FB Payment UI UPDATED RESPONSE :: ', { message: response });
                        console.info('FB Payment UI DATA RESPONSE Error_Code:: ', data && data.error_code);
                        console.info('FB Payment UI DATA RESPONSE Error_Message', data && data.error_message);
                        const dataResult = data && data.error_code;
                        if( productId && productId.toLowerCase().indexOf('piggy') < 0 && data.error_code == 1383010) {
                            let data = {
                                st : sessionToken,
                                pparam: productId,
                                oparam: orderId,
                            }
                            ERROR_OCCURED_IN_PAYMENT_subscribe();
                            //open popup of redirection to classiccasino is user has cancelled the purchase and is not piggy
                            PubSub.publish(PUBSUB_EVENTS_LIST.REDIRECT_PAYMENT, data);
                        }else if(dataResult === -100){
                            // Do nothing as this errro coming from FB UI on windows machines while payment UI open
                            console.info('Payment UI Open');
                        }else if(dataResult){
                            //data.error_code === 1383010 => Cancel
                            //https://developers.facebook.com/docs/games_payments/fulfillment/errorcodes/
                            const webglMsg = { cmd:cmd, msg: JSON.stringify({_statusCode: data.error_code, _json : data.error_message})};
                            SendMessageWebGl(webglMsg);
                            //Analytics - ENGINEERING-start
                            const log = {
                                eventGroup: 'ENGINEERING',
                                eventCode: 'FACEBOOK_PAYMENT_UI',
                                deviceId: userID,
                                eventAttributes: {
                                    ui_element: 'mini_lobby',
                                    from_where: 'facebook_payment_ui',
                                },
                                sessionToken: sessionToken,
                                country: webglPayload.country,
                                operatingSystem: webglPayload.operatingSystem,
                                appVersion: appVersionRollOut,
                            };
                            webglPayload.logEngineeringEvents && analyticsManagerInstance
                                .logAnalytics(webglPayload.analyticsLogs.setAnalyticsData(log))
                                .then(() => {});
                            //Analytics - ENGINEERING-End
                            console.info('FACEBOOK PAYMENT', { error_code: data.error_code, error_message: data.error_message});
                        }else{
                            // Fulfill the payment via SEAM.
                            data.testUser = false;
                            if(webglPayload.facebookUserObject.profile && webglPayload.facebookUserObject.profile.qaTester) {
                                data.testUser = webglPayload.facebookUserObject.profile.qaTester
                            }
                            SeamServices.handleFacbookPayment(data, orderId)
                                .then((seamResponse) => {
                                    console.info('Payment Response', {
                                        message: seamResponse,
                                    });
                                    const msg = seamResponse;
                                    if(msg && msg.message && msg.message == 'Only tester can make sandbox purchase'){
                                        alert('Only tester can make sandbox purchase.');
                                    }
                                    console.info('Payment Response', {
                                        message: JSON.stringify(seamResponse),
                                    });
                                    let webglMsg;

                                    if(seamResponse && seamResponse.success) {
                                        let resp = {
                                            _statusCode: 204,
                                            _httpResponse: {
                                                _trackJs: {
                                                    "method":"POST",
                                                    "url":"https://classic-casino-us-east-1.api-prod.classiccasino.com/production/store/v1/facebook/canvas",
                                                    "logId":"f430af52-c796-4003-9d2c-3cc976756848"
                                                }
                                            }
                                        }
                                        webglMsg = { cmd:cmd, msg: JSON.stringify(resp)};
                                    }else if(seamResponse && seamResponse.success == false && seamResponse.message && seamResponse.message.indexOf('already fulfilled') > -1 ) {
                                        let resp = {
                                            _statusCode: 204,
                                            _httpResponse: {
                                                _trackJs: {
                                                    "method":"POST",
                                                    "url":"https://classic-casino-us-east-1.api-prod.classiccasino.com/production/store/v1/facebook/canvas",
                                                    "logId":"f430af52-c796-4003-9d2c-3cc976756848"
                                                }
                                            }
                                        }
                                        webglMsg = { cmd:cmd, msg: JSON.stringify(resp)};
                                    } else{
                                        webglMsg = { cmd:cmd, msg: JSON.stringify(seamResponse)};
                                    }
                                    SendMessageWebGl(webglMsg)

                                    //Analytics - ENGINEERING-start
                                    fbq('track', 'Purchase', {currency: "USD", value: price});
                                    const log = {
                                        eventGroup: 'ENGINEERING',
                                        eventCode: 'FACEBOOK_PAYMENT_SUCCESS',
                                        deviceId: userID,
                                        eventAttributes: {
                                            ui_element: 'mini_lobby',
                                            from_where: 'facebook_payment_seam',
                                        },
                                        sessionToken: sessionToken,
                                        country: webglPayload.country,
                                        operatingSystem: webglPayload.operatingSystem,
                                        appVersion: appVersionRollOut,
                                    };
                                    webglPayload.logEngineeringEvents && analyticsManagerInstance
                                        .logAnalytics(webglPayload.analyticsLogs.setAnalyticsData(log))
                                        .then(() => {});
                                    //Analytics - ENGINEERING-End
                                })
                                .catch(error => {
                                    //Analytics - ENGINEERING-start
                                    const log = {
                                        eventGroup: 'ENGINEERING',
                                        eventCode: 'FACEBOOK_PAYMENT_ERROR_SEAM',
                                        deviceId: userID,
                                        eventAttributes: {
                                            ui_element: 'mini_lobby',
                                            from_where: 'facebook_payment_seam',
                                        },
                                        sessionToken: sessionToken,
                                        country: webglPayload.country,
                                        operatingSystem: webglPayload.operatingSystem,
                                        appVersion: appVersionRollOut,
                                    };
                                    webglPayload.logEngineeringEvents && analyticsManagerInstance
                                        .logAnalytics(webglPayload.analyticsLogs.setAnalyticsData(log))
                                        .then(() => {});
                                    //Analytics - ENGINEERING-End
                                    console.error('FACEBOOK PAYMENT ERROR SEAM', { error: error.stack });
                                    const webglMsg = { cmd:cmd, msg: JSON.stringify({_statusCode:'500', _json:"seam failure"})};
                                    SendMessageWebGl(webglMsg)
                                });
                        }
                    });
                }
            );
        }
    }

    const executeDeeplink = (cmd) => {
        console.log('Subscribing for Tokens');
        // PubSub.subscribe(PUBSUB_EVENTS_LIST.ADD_TOKENS_METHOD, (target, msg) => {
        //     console.log('Add tokens in client called');
        //     let msg1 = { "Type": "Refresh", "Value": "UserBalance" };
        //     const webglMsg = { cmd:cmd, msg: JSON.stringify({'Type':'Refresh', 'Value':'UserBalance'})};
        //     SendMessageWebGl(webglMsg);
        // });
    };

    const refreshUserBalance = (cmd) => {
        console.log('Refresh user balance subscribe method called');
        PubSub.subscribe(PUBSUB_EVENTS_LIST.ADD_TOKENS_METHOD, (target, msg) => {
            console.log('Add tokens in client called');
            const webglMsg = { cmd: cmd, msg: '' };
            if(isInsideGame) {
                SendMessageWebGl(webglMsg);
            }
        });
    };

    const subscribeToPaypalPayments = () => {
        let webglMsg;
        PubSub.subscribe('GSN_PAYPAL_PAYMENT_DONE', (target, msg) => {
            if(isInsideGame) {
                switch(msg.status){
                    case 'approved':
                        console.log('PAYPAL TRANSACTION APPROVED');
                        //webglMsg = { cmd:cmd, msg: JSON.stringify({_statusCode:200, _json:''})};
                        //SendMessageWebGl(webglMsg)
                        break;
                    case 'completed':
                        webglMsg = { cmd:"InitializePurchase,7", msg: JSON.stringify({_statusCode:200, _json:''})};// AFTER COMPLETE REMOVE LOADER
                        SendMessageWebGl(webglMsg);
                        setCanvasFocus();
                        break;
                    case 'transaction_verification_failed':
                        webglMsg = { cmd:"InitializePurchase,7", msg: JSON.stringify({_statusCode:500, _json:'Invalid Response from Paypal'})};
                        SendMessageWebGl(webglMsg);
                        setCanvasFocus();
                        break;
                    case 'close_clicked':
                        webglMsg = { cmd:"InitializePurchase,7", msg: JSON.stringify({_statusCode:499, _json:'client has closed the request before the server could send a response'})};
                        SendMessageWebGl(webglMsg);
                        setCanvasFocus();
                        break;
                }
            }
            console.log('WEBGL_IS_LOADED Container',JSON.stringify(msg));

        });
    }

    PubSub.subscribe('IS_REDIRECTED_PAYMENT_USER', (target, msg) => {
        subscribeToPaypalPayments();
    });

    const InitializeGSNPayment =(cmd, productId, tokenAmount, orderId, price, purchaseType)=>{
        const paymentInfo = {
            paymentInit:true,
            item:{
                item_id:orderId,
                product_id:productId,
                token_count:tokenAmount,
                token_Amount: price, /// Need to fix get from client,
                purchaseType: purchaseType,
            }
        }
        let webglMsg;
        PubSub.publish("GSN_PAYPAL_PAYMENT", paymentInfo); //gameName = home or game name
        PubSub.subscribe('GSN_PAYPAL_PAYMENT_DONE', (target, msg) => {
            console.log('WEBGL_IS_LOADED Container',JSON.stringify(msg));
            switch(msg.status){
                case 'approved':
                    console.log('PAYPAL TRANSACTION APPROVED');
                    //webglMsg = { cmd:cmd, msg: JSON.stringify({_statusCode:200, _json:''})};
                    //SendMessageWebGl(webglMsg)
                    break;
                case 'completed':
                    webglMsg = { cmd:cmd, msg: JSON.stringify({_statusCode:200, _json:''})};// AFTER COMPLETE REMOVE LOADER
                    SendMessageWebGl(webglMsg);
                    setCanvasFocus();
                    break;
                case 'transaction_verification_failed':
                    webglMsg = { cmd:cmd, msg: JSON.stringify({_statusCode:500, _json:'Invalid Response from Paypal'})};
                    SendMessageWebGl(webglMsg);
                    setCanvasFocus();
                    break;
                case 'close_clicked':
                    webglMsg = { cmd:cmd, msg: JSON.stringify({_statusCode:499, _json:'client has closed the request before the server could send a response'})};
                    SendMessageWebGl(webglMsg);
                    setCanvasFocus();
                    break;
            }
        });
    }

    const setCanvasFocus =()=>{
        const canvas = document.getElementsByTagName('canvas') && document.getElementsByTagName('canvas')[0];
        if (canvas && webglPayload && webglPayload.browserName === 'Edge') {
            console.log('FOCUS CHANGED...')
            canvas.focus();
        }
    }

    const payment =(cmd, productId, tokenAmount, orderId, price, purchaseType)=>{
        console.log('PLATFORM:::', platform);
        if(platform===PLATFORM_FB){
            InitializePayment(cmd, productId, tokenAmount, price, orderId);
            cmdOfPayment = cmd;
        }else{
            InitializeGSNPayment(cmd, productId, tokenAmount, orderId, price, purchaseType);
        }
    }
    const setCurrentState = (state)=>{
        console.log('Data from setCurrentState ::', state);
        PubSub.publish(PUBSUB_EVENTS_LIST.GAME_NAME, state); //gameName = home or game name
    }
    const ReloadWebPage = ()=>{
        setTimeout(()=>{
            window && window.location.reload();
        },100);
    }
    const GsnWebGLOpenURL = (str)=>{
        PubSub.publish(PUBSUB_EVENTS_LIST.OPEN_CONTACT_SUPPORT, str); //Contact Support Open Link
    }
    const SendErrorToCanvas = (err)=>{
        PubSub.publish(PUBSUB_EVENTS_LIST.SEAM_SERVICE_ERROR, err);
    }
    const SendMessageWebGl = (webglMsg) =>{
        console.log('DATA SEND MESSAGE::', webglMsg);
        gameInstance.SendMessage(
              unityCommunicatorObject,
              unityCommunicatorFunctionName,
              JSON.stringify(webglMsg)
          );
      }
      const registerForFullScreenChange = (cmd)=>{
        PubSub.subscribe(PUBSUB_EVENTS_LIST.ESC_FULL_SCREEN, (target, msg) => {
            if (webglPayload && webglPayload.browserName === 'Safari' && msg ==='true') {
                alert(`You're entering full screen mode! To exit full screen mode, press Esc.`);
            }
            //gameInstance.SendMessage("WebGL_Canvas", "OnFullScreenStateChange", msg);
            const webglMsg = { cmd:cmd, msg: msg};
            SendMessageWebGl(webglMsg)
        });
      }
      const registerForVideoAvailabilityChange = (cmd)=>{
        console.log('registerForVideoAvailabilityChange');
          messagesent = false;
          PubSub.subscribe(PUBSUB_EVENTS_LIST.VIDEO_ADS_AVAILABLE, (target, msg) => {
              if(messagesent === false || messagesent === 'false' || !messagesent) {
                  messagesent = true;
                  const webglMsg = { cmd:cmd, msg: msg};
                  //   setTimeout(()=>{
                  SendMessageWebGl(webglMsg)
                  //    }, 10000);
              }
              console.log('registerForVideoAvailabilityChange inside', cmd, msg);
          });
      }
    const ERROR_OCCURED_IN_PAYMENT_subscribe = (cmd)=>{
        console.log("I am called in error");
        PubSub.subscribe(PUBSUB_EVENTS_LIST.ERROR_OCCURED_IN_PAYMENT, (target, msg) => {
            console.log("I am called in error");

            // const webglMsg = { cmd:cmd, msg: JSON.stringify({_statusCode:'500', _json:"seam failure"})};
            // SendMessageWebGl(webglMsg)
            // const webglMsg = { cmd:cmd, msg: JSON.stringify({_statusCode:'1383010', _json:"User canceled the order."})};
            const webglMsg = { cmd:cmdOfPayment, msg: JSON.stringify({'_statusCode':'1383010', '_json':'User canceled the order.'})};
            SendMessageWebGl(webglMsg);
        });
    }


      const registerForRewardVideoEvent = (cmd)=>{
          PubSub.subscribe(PUBSUB_EVENTS_LIST.VIDEO_ADS_COMPLETED, (target, msg) => {
              const webglMsg = { cmd:cmd, msg: msg};
              SendMessageWebGl(webglMsg)
              let rewardEventType = JSON.parse(msg).RewardEventType;
              console.log('registerForRewardVideoEvent', rewardEventType, msg);
            if (rewardEventType == "6") {
              setTimeout(()=>{
                PubSub.publish('REFRESH_ADS', cmd);
              }, 1000);
            }
          });
      }
      const playVideo = (str)=>{
          console.log('playVideo test');
          PubSub.publish(PUBSUB_EVENTS_LIST.PLAY_VIDEO_ADS, str);
      }

      const initializeIronSource = (str) => {
        SeamServices.getWebAdsProvider().then(data => {
            let fcResponseValue = JSON.parse(data);
            console.log('fcResponseValue', fcResponseValue, str);
            if(fcResponseValue && (fcResponseValue.googleAds == true || fcResponseValue.googleAds == 'true')) {
                console.log('initializeGoogleAds');
                PubSub.publish(PUBSUB_EVENTS_LIST.initializeAds, fcResponseValue);
            } else if (fcResponseValue && (fcResponseValue.ironRVAds == true || fcResponseValue.ironRVAds == 'true')) {
                console.log('initializeIronSource');
                PubSub.publish(PUBSUB_EVENTS_LIST.initializeIronSource, str);
            }
        })
      }

      const registerForKeyBoardInput = (cmd) => {
          PubSub.subscribe(PUBSUB_EVENTS_LIST.BLOCK_USER_TYPING, (target, msg) => {
              const webglMsg = { cmd:cmd, msg: msg};
              SendMessageWebGl(webglMsg)
          });
      }

    const callSelf = (a_webglPayload, a_gameInstance, a_loginTimeSyncDelay, a_appVersionRollOut)=>{
        webglPayload = a_webglPayload;
        gameInstance = a_gameInstance;
        loginTimeSyncDelay = a_loginTimeSyncDelay;
        appVersionRollOut = a_appVersionRollOut;
        return {
            initialize:init,
            requestForLoggedInData:requestForLoginData,
            requestForSwitchToContainer:requestForSwitchToContainer,
            updateErrorFromContainer:updateErrorFromContainer,
            openURLWithOptionalType:openURL,
            initializePurchase: payment,//platform === 'FB' ? InitializePayment : InitializeGSNPayment ,
            sendCurrentAppLocation:setCurrentState,
            reloadWebPage:ReloadWebPage,
            gsnWebGLOpenURL:GsnWebGLOpenURL,
            sendAppSoftCrashDetails:SendErrorToCanvas,
            updateDashboardLoadingProgress:UpdateLoadingProgress,
            registerForFullScreenChange:registerForFullScreenChange,
            registerForVideoAvailabilityChange:registerForVideoAvailabilityChange,
            registerForRewardVideoEvent: registerForRewardVideoEvent,
            playVideo: playVideo,
            initializeIronSource: initializeIronSource,
            registerForKeyBoardInput: registerForKeyBoardInput,
            executeDeeplink: executeDeeplink,
            refreshUserBalance: refreshUserBalance
        }
    }
    return {
        callSelf:callSelf,
    }
})();
