import * as React from 'react';
import css from './style.css';
import NetworkServices from '../../services/networkServices';
import SeamServices from '../../services/seamService';
import { clearTimeout } from 'timers';

type PropsT = {
    closeModal: func,
};
type StateT = {};

export default class PayPalCheckOut extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {
            paidFor: false,
            error: null,
            url:''
        };
        this.renderPaypalPurchaseModal = this.renderPaypalPurchaseModal.bind(this);
        this.preparePurchasePayload = this.preparePurchasePayload.bind(this);
        this.geturl = this.geturl.bind(this);
        this.closePaymentModal = this.closePaymentModal.bind(this);
        this.gsnComCommunication = this.gsnComCommunication.bind(this);
        this.resetIfram = this.resetIfram.bind(this);
        this.paypalRef = 'div';

    }

    componentDidMount(){
        window.addEventListener("message", this.gsnComCommunication);
        this.geturl();
    }
    componentWillUnmount(){
        window.removeEventListener('message', this.gsnComCommunication);
    }
    gsnComCommunication(){
        //To-do: Important to revert this and add payment-classiccasino url after migration
        // //const baseWebglUrl="//dev.gsn.com";
        // const baseWebglUrl=process.env.BASE_GSNCOM_URL;
        // if (event.origin.indexOf(baseWebglUrl)<0){
        //     return;
        // }
        console.info('Received callback', event.data);
        if(event.data && event.data.hasOwnProperty('state')){
            const respState = event.data.state;
            switch (respState){
                case 'approved':
                    console.info('Received callback approved');
                    this.props.paymentStatus(respState);
                    break;
                case 'completed':
                    console.info('Received callback completed');
                    this.closePaymentModal(respState);
                    break;
                case 'transaction_verification_failed':
                    console.info('Received callback transaction_verification_failed');
                        this.closePaymentModal(respState);
                        break;
                case 'close_clicked':
                    console.info('Received callback close_clicked');
                    this.closePaymentModal(respState);
                case 'resized':
                    console.info('Received callback resized');
                    const maxHeight = event.data.height && parseInt(event.data.height) + 40 + "px";
                    const height = event.data.height && parseInt(event.data.height) + 70 + "px";
                    this.resetIfram(height, maxHeight);
                    break;
            }
        }
    }

    resetIfram(height, maxHeight){
        const element = document.getElementById('paypal_modal');
        const ele_iframe = document.getElementById("randomid");
        if(ele_iframe){
            ele_iframe.height = height; // this the same value as iframe.
        }
        if(element){
            element.setAttribute("style", `max-height:${maxHeight}; height: ${height};border-radius:10px;`);
        }
    }


    geturl(){
        const payload = this.preparePurchasePayload();
        console.log("CHECK THIS === ", JSON.stringify(payload));
        const seamUser = this.props.seamUser;
        SeamServices.getOrderSignature(payload, seamUser.sessionToken)
            .then((resp)=>{
                const signature = resp._signed_request.replace(/\+/g, '-').replace(/\//g, '_');
                const payload = resp._payload;
                this.renderPaypalPurchaseModal(signature, payload);
            })
            .catch((error)=>{
                console.log('SEAM getOrderSignature ERROR>>>>', error);
            })

    }

    closePaymentModal(state){
        this.props.paymentStatus(state);
        this.props.closeModal();
    }

    renderPaypalPurchaseModal(signature, payload){
        const element = document.getElementById('paypal_modal');
        const modalStartTS = new Date();
        const pp_checkout_iframe_source_url = process.env.PAYPAL_CHECKOUT;
        const iframe_src = `${pp_checkout_iframe_source_url}sig=${signature}&pload=${payload}`;
        const iframe = document.createElement('iframe');
        iframe.frameBorder = 0;
        iframe.marginwidth = 0;
        iframe.marginheight = 0;
        iframe.width = "100%";
        iframe.height = "100%";
        iframe.id = "randomid"; //NEED TO RIGHT CODE TO GET RANDOM ID
        iframe.setAttribute("src", iframe_src);
        iframe.setAttribute("style", "background-color:white; border-radius:10px;");
        element.appendChild(iframe);
    }

    preparePurchasePayload(){
        //NOTE ->> 1. TokenAmount($) Number of Token,
        const product = this.props.product;
        const seamUser = this.props.seamUser;
        const date = new Date();
        const timestamp = date.getTime();
        const payload = {
            "action": "casinoPurchase",
            "user_id": `${seamUser._id}`,
            "session_id": `${seamUser.sessionToken}`,
            "timestamp": timestamp,
            "pp_reference":"casino",
            "item": {
                "name": `purchase ${product.token_count} ${product.purchaseType} for price ${product.token_Amount}`,
                "price": `${product.token_Amount}`,//tokenAmount
                "description": `Buy ${product.token_count} ${product.purchaseType}, ${product.product_id}, ${product.item_id}`,
                "item_id": `${product.item_id}`,//Order ID
                "item_data": {
                    "user_id": `${seamUser._id}`
                }
                }
            }
        return payload;
    }
    render() {
        return (<div className={css.card_fields_container}>
                    <div className={css.ftue_frame} />
                        <div id="paypal_modal" className={css.modal_content}>
                    </div>
                </div>);
    }
};
