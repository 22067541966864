// @flow
import type { ActionT, AppT } from './types';
import { ActionTypes } from './actions';
import uuid from 'uuid';

export const initialState: AppT = Object.freeze({
    locale: 'en-US',
    signedRequest: '',
    gsnConfig: {
        cdnUrl: 'https://s3.amazonaws.com/ctwo-webgl-prod/sounds',
        cdnAdImg: 'https://secure-assets.rubiconproject.com/static/psa/2.jpg',
        cdnUrlAd: 'https://lovehasnolabels.com/about/rising/',
    },
    userConfig: {
        lobbyMusicEnabled: '',
        sfxEnabled: true,
        isEuropeanCitizen: false,
        tokenBalance: null,
        seamSession: '',
        gsnCredentials:'',
        refreshRate: 0,
        appSessionId: uuid.v1(),
        isContainerDisplayed: false,
        isWebGlLoaded: false,
        fbDeatils: '',
        noOfTimesPlayed: 0,
        maximumTokens: 5000,
        gameIsOver: false,
        seamTokenBalance: '',
        countWin: 0,
        countWinText: 0,
        gameRoundNo: 0,
        totalWinAmount: 0,
        seamUser: '',
        fbUserId: '',
        tokensWonPerRound: 0,
        gameMathModel: [],
        leftCardVal: 0,
        rightCardVal: 0,
        highorLowBtn: '',
    },
    componentConfig: {},
    modalQueue: [],
    isModalOpen: false,
    hostQueue: [],
});

export default (state: AppT = initialState, action: ActionT): AppT => {
    const { type, payload = {} } = action;

    switch (type) {
        case ActionTypes.SETLOCALE: {
            return {
                ...state,
                locale: payload,
            };
        }
        case ActionTypes.TOGGLE_LOBBY_MUSIC: {
            return {
                ...state,
                userConfig: { ...state.userConfig, lobbyMusicEnabled: payload },
            };
        }

        case ActionTypes.TOGGLE_SFX_MUSIC: {
            return {
                ...state,
                userConfig: { ...state.userConfig, sfxEnabled: payload },
            };
        }
        case ActionTypes.OPEN_MODAL: {
            return {
                ...state,
                modalQueue: [...state.modalQueue, payload],
            };
        }
        case ActionTypes.CLOSE_MODAL: {
            const newModalQueue = [...state.modalQueue];
            newModalQueue.shift();
            return {
                ...state,
                modalQueue: newModalQueue,
                isModalOpen: false,
            };
        }
        case ActionTypes.SET_MODAL_STATE: {
            return {
                ...state,
                isModalOpen: payload,
            };
        }
        case ActionTypes.UPDATE_TOKEN_BALANCE: {
            return {
                ...state,
                userConfig: { ...state.userConfig, seamTokenBalance: payload },
            };
        }

        case ActionTypes.OPEN_HOST: {
            return {
                ...state,
                hostQueue: [...state.hostQueue, payload],
            };
        }
        case ActionTypes.CLOSE_HOST: {
            const newHostQueue = [...state.hostQueue];
            newHostQueue.shift();
            return {
                ...state,
                hostQueue: newHostQueue,
            };
        }

        case ActionTypes.GET_TOKEN_BALANCE: {
            return {
                ...state,
                userConfig: { ...state.userConfig, tokenBalance: payload },
            };
        }
        case ActionTypes.GET_SEAM_SESSION: {
            return {
                ...state,
                userConfig: { ...state.userConfig, seamSession: payload },
            };
        }
        case ActionTypes.SET_SEAM_SESSION: {
            return {
                ...state,
                userConfig: { ...state.userConfig, seamSession: payload },
            };
        }
        case ActionTypes.SET_GSN_CREDENTIALS: {
            return {
                ...state,
                userConfig: { ...state.userConfig, gsnCredentials: payload },
            };
        }
        case ActionTypes.GET_GAME_REFRESH_RATE: {
            return {
                ...state,
                userConfig: { ...state.userConfig, refreshRate: payload },
            };
        }
        case ActionTypes.SET_GAME_REFRESH_RATE: {
            return {
                ...state,
                userConfig: { ...state.userConfig, refreshRate: payload },
            };
        }
        case ActionTypes.GET_APP_SESSION_ID: {
            return {
                ...state,
                userConfig: { ...state.userConfig, appSessionId: payload },
            };
        }
        case ActionTypes.SET_APP_SESSION_ID: {
            return {
                ...state,
                userConfig: { ...state.userConfig, appSessionId: payload },
            };
        }
        case ActionTypes.CHECK_CONTAINER: {
            return {
                ...state,
                userConfig: { ...state.userConfig, isContainerDisplayed: payload },
            };
        }
        case ActionTypes.IS_WEBGL_LOADED: {
            return {
                ...state,
                userConfig: { ...state.userConfig, isWebGlLoaded: payload },
            };
        }
        case ActionTypes.SET_FB_DATA: {
            return {
                ...state,
                userConfig: { ...state.userConfig, fbDeatils: payload },
            };
        }
        case ActionTypes.UPDATE_GAME_PLAY_COUNT: {
            return {
                ...state,
                userConfig: { ...state.userConfig, noOfTimesPlayed: payload },
            };
        }
        case ActionTypes.MINI_GAME_OVER: {
            return {
                ...state,
                userConfig: { ...state.userConfig, gameIsOver: payload },
            };
        }
        case ActionTypes.COUNT_WIN: {
            return {
                ...state,
                userConfig: { ...state.userConfig, countWin: payload },
            };
        }
        case ActionTypes.UPDATE_GAME_ROUND: {
            return {
                ...state,
                userConfig: { ...state.userConfig, gameRoundNo: payload },
            };
        }
        case ActionTypes.COUNT_WIN_TEXT: {
            return {
                ...state,
                userConfig: { ...state.userConfig, countWinText: payload },
            };
        }
        case ActionTypes.TOTAL_WIN_AMOUNT: {
            return {
                ...state,
                userConfig: { ...state.userConfig, totalWinAmount: payload },
            };
        }
        case ActionTypes.SET_FB_USER_ID: {
            return {
                ...state,
                userConfig: { ...state.userConfig, fbUserId: payload },
            };
        }
        case ActionTypes.TOKENS_WON_PER_ROUND: {
            return {
                ...state,
                userConfig: { ...state.userConfig, tokensWonPerRound: payload },
            };
        }
        case ActionTypes.GAME_MATH_MODEL: {
            return {
                ...state,
                userConfig: { ...state.userConfig, gameMathModel: payload },
            };
        }
        case ActionTypes.SET_LEFT_CARD_VALUE: {
            return {
                ...state,
                userConfig: { ...state.userConfig, leftCardVal: payload },
            };
        }
        case ActionTypes.SET_RIGHT_CARD_VALUE: {
            return {
                ...state,
                userConfig: { ...state.userConfig, rightCardVal: payload },
            };
        }
        case ActionTypes.BUTTON_CLICKED: {
            return {
                ...state,
                userConfig: { ...state.userConfig, highorLowBtn: payload },
            };
        }
    }

    return state;
};
