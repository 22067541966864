//@flow
import * as React from 'react';
import css from './style.css';
import GdprService from '../../services/gdprService';
import analyticsManagerInstance from '../../managers/analyticsManager';
import analyticslog from '../../constants/analyticslog';
import {PLATFORM_GSNCOM} from '../../constants/matcher';
import letsgobuuton from '../Lobby/images/Green_Button.png';
import closebuuton from '../Lobby/images/Close_button.png';
import backgroundImg from '../Lobby/images/Bg2.png'
import PubSub from "pubsub-js";

type PropsT = {
    displaymsg: any,
};
type StateT = {};
export default class SuccesPurchase extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {
            displaymsg: props.displaymsg
        };
        this.backToLobby = this.backToLobby.bind(this);
    }
    componentDidMount() {}

    async backToLobby() {
        PubSub.publish('CLOSE_PAYMENT_REDIRECTION_SUCCESS_POPUP', true);
    }
    render() {
        const buttonStyle = css['GDPR-sprite-sheet-btn-yes-active'];
        return (
            <div

                id="gdpr-model-popup"
                className={`${css['GDPR-sprite-sheet-popup']} ${css['gdpr-model-popup']}`}
                style={{backgroundImage: `url(${backgroundImg})`}}
            >

                <a
                    className={`${buttonStyle} `}
                    id="buttonClass"
                    onClick={this.backToLobby}
                    style={{position: 'absolute', backgroundSize: '100% 100%', top:'-10px' , right: '-15px', width: '50px', height: '50px', backgroundImage: `url(${closebuuton})`}}
                />

                <div id="privacyInformation">

                    <div className={css['information-text']}>
                        {this.state.displaymsg}
                    </div>
                    <button className={`${css.buttonClass}`} onClick={this.backToLobby}></button>
                </div>
            </div>
        );
    }
}
