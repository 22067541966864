import { connect } from 'react-redux';
import IncompatibleBrowser from './IncompatibleBrowser';
import { closeModal } from '../../store/app/actions';

const mapStateToProps = (state) => ({
    fbUserId: state.app.userConfig.fbUserId,
    appSessionId: state.app.userConfig.appSessionId,
});

const mapDispatchToProps = { closeModal };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(IncompatibleBrowser);
