const getUrlParameter = function(name) {
    name = name.replace(/[\[]/, '\\[').replace(/[\]]/, '\\]');
    var regex = new RegExp('[\\?&]' + name + '=([^&#]*)');
    var results = regex.exec(location.search);
    return results === null ? '' : decodeURIComponent(results[1].replace(/\+/g, ' '));
};
export const getUnityOptionalBuild = function(){
    const av = getUrlParameter('av');
    const secret = getUrlParameter('secret');
    const isOptionalBuild = secret && (secret === process.env.UNITY_BUILD_SECRET);
    if(isOptionalBuild){
        return av;
    }else{
        return undefined;
    }
}

//-----------------------
// Custom functions
//-----------------------
export function getOptionalBuild (platform) {
    const optionalBuild =getUnityOptionalBuild();
    return optionalBuild;
}

