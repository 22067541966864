//@flow
import * as React from 'react';
import css from './style.css';
import { getOS } from '../../managers/resolutionManager';
import { updateMacLinks, updateWinLinks } from '../../constants/matcher';
import analyticsManagerInstance from '../../managers/analyticsManager';
import analyticslog from '../../constants/analyticslog';

type PropsT = {


};
type StateT = {};

export default class IncompatibleCountry extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
    }


    render() {
        const logoImg =  process.env.AWS_URL + 'GSN_Casino_Logo.png?ver=' + process.env.IMAGE_VERSION;
            return (
                <div className={`row ${css.err_top}`} style={{ backgroundImage: 'none'}}>
                    <div className="col-md-12" style={{marginTop: '12%'}}>
                        <img className={css.logoImage} src={logoImg} />

                        <div className={`row ${css.err_content1}`}>
                            <div className="col-md-12">
                                <div className={css.text_21}> GSN Casino is not available in this region. </div>
                            </div>
                        </div>
                    </div>
                </div>
            );
    }
}
