// @flow
import * as React from 'react';
import {PLATFORM_GSNCOM} from '../../constants/matcher';
import SeamServices from '../../services/seamService';
import PubSub from 'pubsub-js';

type PropsT = {
};
type StateT = {};


export default class GoogleVideoAds extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {
            googletag: {},
            event: {},
            rewardedSlot: {},
            placement: '',
            isFullScreenBefore: false
        };
        this.showAd = this.showAd.bind(this);
        this.showAdGsnCom = this.showAdGsnCom.bind(this);
        this.initiateGoogleAds = this.initiateGoogleAds.bind(this);
        this.initiateGoogleAdsInContainer = this.initiateGoogleAdsInContainer.bind(this);
        this.initiateGoogleAdsGsnCom = this.initiateGoogleAdsGsnCom.bind(this);
        this.adsCommunicatorFunction = this.adsCommunicatorFunction.bind(this);

    }

    componentDidMount() {
        this.initiateGoogleAdsInContainer();
        window.addEventListener("message", this.adsCommunicatorFunction);
        PubSub.subscribe('REFRESH_ADS', (target, msg) => {
            if(this.props.platform === PLATFORM_GSNCOM) {
                console.log('Re-initiate GoogleAds in classiccasino called');
                this.initiateGoogleAdsGsnCom(msg);
            }else {
                console.log('Re-initiate GoogleAds in container called');
                googletag.destroySlots();
                this.initiateGoogleAds(msg);
            }
        });
    }

    initiateGoogleAds(data) {
        console.log("googleAds to init", data);
        let that = this;
        // const params = {
        //     applicationKey		: appid,
        //     applicationUserId	: this.props.userData.id
        // };

        window.googletag = window.googletag || { cmd: [] };
        this.setState({ googletag: googletag });

        let rewardedSlot;

        // Enable Google Ads services.
        googletag.cmd.push(() => {
            // Remove all event listerners before adding new ones
            // googletag.pubads().removeEventListener('rewardedSlotReady', () => {console.log('rewardedSlotReady event removed')});
            // googletag.pubads().removeEventListener('impressionViewable', () => {console.log('impressionViewable event removed')});
            // googletag.pubads().removeEventListener('slotOnload', () => {console.log('slotOnload event removed')});
            // googletag.pubads().removeEventListener('slotRenderEnded', () => {console.log('slotRenderEnded event removed')});
            // googletag.pubads().removeEventListener('slotVisibilityChanged', () => {console.log('slotVisibilityChanged event removed')});
            // googletag.pubads().removeEventListener('slotRequested', () => {console.log('slotRequested event removed')});
            // googletag.pubads().removeEventListener('slotResponseReceived', () => {console.log('slotResponseReceived event removed')});
            // googletag.pubads().removeEventListener('rewardedSlotClosed', () => {console.log('rewardedSlotClosed event removed')});
            // googletag.pubads().removeEventListener('rewardedSlotGranted', () => {console.log('rewardedSlotGranted event removed')});

            // googletag.defineSlot('/22639388115/rewarded_web_example', [400, 380], 'div-gpt-ad-1706609394679-0').addService(googletag.pubads());
            // googletag.pubads().enableSingleRequest();
            // googletag.pubads().addEventListener('slotRenderEnded', function(event) {
            //   console.log('Slot has been rendered:');
            // });
            // googletag.enableServices();
            // googletag.pubads().disableInitialLoad();
            // googletag.pubads().enableSingleRequest();
            // googletag.enableServices();

            // Ad Format
            let adFormat = data.adFormat == 1 
                            ? googletag.enums.OutOfPageFormat.BOTTOM_ANCHOR 
                            : data.adFormat == 2 
                                ? googletag.enums.OutOfPageFormat.INTERSTITIAL 
                                : data.adFormat == 3 
                                    ? googletag.enums.OutOfPageFormat.LEFT_SIDE_RAIL 
                                    : data.adFormat == 4 
                                        ? googletag.enums.OutOfPageFormat.REWARDED 
                                        : data.adFormat == 5 
                                            ? googletag.enums.OutOfPageFormat.RIGHT_SIDE_RAIL 
                                            : data.adFormat == 6 
                                                ? googletag.enums.OutOfPageFormat.TOP_ANCHOR 
                                                : googletag.enums.OutOfPageFormat.REWARDED;

            // Register the slot.
            rewardedSlot = googletag.defineOutOfPageSlot(
                data.adUnit,
                adFormat,
            );

            // rewardedSlot = googletag.defineSlot(
            //     '/22639388115/rewarded_web_example',
            //     [300, 250],
            //     'div-gpt-ad-1706793635772-0'
            // );

            if(rewardedSlot) {
                rewardedSlot.addService(googletag.pubads());
                console.log("rewardedSlot", rewardedSlot);
                // This listener will be called when a rewarded ad slot is ready to be displayed.
                googletag.pubads().addEventListener("rewardedSlotReady", (event) => {
                    const slot = event.slot;
                    this.setState({ event: event });
                    PubSub.publish('VIDEO_ADS_AVAILABLE', 'true');
                    that.showAd();
                    console.log("Rewarded ad slot", slot.getSlotElementId(), "is ready to be displayed.");
                });

                // This listener will be called when an impression is considered viewable.
                googletag.pubads().addEventListener("impressionViewable", (event) => {
                    const slot = event.slot;
                    console.log("Impression has become viewable.", slot);
                    var REFRESH_KEY = 'refresh';
                    var REFRESH_VALUE = 'true';
                    // Number of seconds to wait after the slot becomes viewable.
                    // var SECONDS_TO_WAIT_AFTER_VIEWABILITY = 60;
                    // if (slot.getTargeting(REFRESH_KEY).indexOf(REFRESH_VALUE) > -1) {
                    //     setTimeout(function() {
                    //       googletag.pubads().refresh([slot]);
                    //     }, SECONDS_TO_WAIT_AFTER_VIEWABILITY * 1000);
                    //   }
                });

                // This listener will be called when a slot's creative iframe load event fires.
                googletag.pubads().addEventListener("slotOnload", (event) => {
                    const slotId = event.slot.getSlotElementId();
                    console.log("Creative iframe load event has fired.", slotId);
                });

                // This listener will be called when a slot has finished rendering.
                googletag.pubads().addEventListener("slotRenderEnded", (event) => {
                    const slotId = event.slot.getSlotElementId();

                    // Record details of the rendered ad.
                    const details = {
                    "Advertiser ID": event.advertiserId,
                    "Campaign ID": event.campaignId,
                    "Company IDs": event.companyIds,
                    "Creative ID": event.creativeId,
                    "Creative Template ID": event.creativeId,
                    "Is backfill?": event.isBackfill,
                    "Is empty?": event.isEmpty,
                    "Label IDs": event.labelIds,
                    "Line Item ID": event.lineItemId,
                    //   Size: typeof event.size === "string" ? event.size : event.size?.join("x") ?? null,
                    "Slot content changed?": event.slotContentChanged,
                    "Source Agnostic Creative ID": event.sourceAgnosticCreativeId,
                    "Source Agnostic Line Item ID": event.sourceAgnosticLineItemId,
                    "Yield Group IDs": event.yieldGroupIds,
                    };

                    console.log("Slot has finished rendering.", slotId, details);
                });

                // This listener will be called when the specified service actually
                // sets an ad request for a slot. Each slot will fire this event, even
                // though they may be batched together in a single request if single
                // request architecture (SRA) is enabled.
                googletag.pubads().addEventListener("slotRequested", (event) => {
                    const slotId = event.slot.getSlotElementId();
                    console.log("Slot has been requested.", slotId);
                });

                // This listener will be called when an ad response has been received for
                // a slot.
                googletag.pubads().addEventListener("slotResponseReceived", (event) => {
                    const slotId = event.slot.getSlotElementId();
                    console.log("Ad response has been received.", slotId);
                });

                // This listener will be called whenever the on-screen percentage of an ad
                // slot's area changes.
                googletag.pubads().addEventListener("slotVisibilityChanged", (event) => {
                    const slotId = event.slot.getSlotElementId();

                    // Record details of the event.
                    const details = { "Visible area": `${event.inViewPercentage}%` };

                    console.log("Visibility has changed.", slotId, details);
                });

                googletag.pubads().addEventListener("rewardedSlotClosed", (event) => {
                    const slot = event.slot;
                    console.log("Rewarded ad slot", slot.getSlotElementId(), "has been closed.");
                    let msg = {
                        RewardEventType : '6',
                        data : JSON.stringify({placement_reward_amount : 1,placement_reward_name : that.state.placement, placement_name: that.state.placement}),
                    };
                    msg = JSON.stringify(msg);
                    if(that.state.isFullScreenBefore) {
                        const elemtoZoom = document.getElementById('gameContainer');
                        if (elemtoZoom && !document.mozFullScreen && !document.webkitFullScreen) {
                            if (elemtoZoom.mozRequestFullScreen) {
                                elemtoZoom.mozRequestFullScreen();
                            } else {
                                elemtoZoom.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
                            }
                        }
                        PubSub.publish('ESC_FULL_SCREEN','false');
                    }
                    console.log("video ads", msg);
                    PubSub.publish('VIDEO_ADS_COMPLETED', msg);
                    googletag.destroySlots([rewardedSlot]);
                });

                // This listener will be called whenever a reward is granted for a rewarded ad.
                googletag.pubads().addEventListener("rewardedSlotGranted", (event) => {
                    const slot = event.slot;
                    console.group("Reward granted for slot", slot.getSlotElementId(), ".");
                    let msg2 = {
                        RewardEventType : '2',
                        data : 'empty',
                    };
                    msg2 = JSON.stringify(msg2);
                    PubSub.publish('VIDEO_ADS_COMPLETED', msg2);
                });
                this.setState({ rewardedSlot: rewardedSlot });
                googletag.enableServices();
                googletag.display(rewardedSlot);
            }
        });

            // Video Ad Completed
            //     console.log("Ad Video completed");
            //     let msg2 = {
            //         RewardEventType : '2',
            //         data : 'empty',
            //     };
            //     msg2 = JSON.stringify(msg2);
            //     PubSub.publish('VIDEO_ADS_COMPLETED', msg2);
            //     googletag.destroySlots();
            // });


    }

    initiateGoogleAdsGsnCom(appid) {
       // window.addEventListener("message", this.adsCommunicatorFunction);
        let userid = "123456789";
        if(this.props.userData.id) {
            userid = this.props.userData.id;
        }
        else if(this.props.userData.authResponse && this.props.userData.authResponse.seedUserId){
            userid = this.props.userData.authResponse.seedUserId;
        }
        else {

        }
        const messageToSend = {
            type : 'info',
            msg : {
                data: "videoads",
                action: "initiateAds",
                appid : appid,
                seamUserId: userid
            }
        };
        window.parent.postMessage(JSON.stringify(messageToSend), '*');
        console.log('Initiate GoogleAds in gsncom called');
    }

    initiateGoogleAdsInContainer(){
        PubSub.subscribe('initializeAds', (target, msg) => {
            if(this.props.platform === PLATFORM_GSNCOM) {
                console.log('Initiate GoogleAds in classiccasino called');
                this.initiateGoogleAdsGsnCom(msg);
            }else {
                console.log('Initiate GoogleAds in container called');
                this.initiateGoogleAds(msg);
            }
        });
    }

    showAdGsnCom() {
        PubSub.subscribe('PLAY_VIDEO_ADS', (target, msg) => {
            this.setState({placement: msg});
            if (msg) {
                if (
                    document.fullscreenElement ||
                    document.webkitFullscreenElement ||
                    document.mozFullScreenElement
                ) {
                    this.setState({isFullScreenBefore: true});
                    this.props.exitFullScreen();
                    PubSub.publish('ESC_FULL_SCREEN','false');
                }
                let msg2 = {
                    RewardEventType : '3',
                    data : 'empty',
                };
                msg2 = JSON.stringify(msg2);
                PubSub.publish('VIDEO_ADS_COMPLETED', msg2);
                if(this.props.browserName == 'Safari' && this.state.isFullScreenBefore) {
                    setTimeout(() => {
                        const messageToSend = {
                            type : 'info',
                            msg : {
                                data: "videoads",
                                action: "showads",
                            }
                        };
                        window.parent.postMessage(JSON.stringify(messageToSend), '*');
                        console.log('send call show ads to gsncom');
                    },1000);
                }
                else {
                    const messageToSend = {
                        type : 'info',
                        msg : {
                            data: "videoads",
                            action: "showads",
                        }
                    };
                    window.parent.postMessage(JSON.stringify(messageToSend), '*');
                    console.log('send call show ads to gsncom in other browser');
                }
            }
        });
    }

    showAd() {
        console.log('show ad called');
        PubSub.subscribe('PLAY_VIDEO_ADS', (target, msg) => {
            this.setState({placement: msg});
            if (msg) {
                if (
                    document.fullscreenElement ||
                    document.webkitFullscreenElement ||
                    document.mozFullScreenElement
                ) {
                    this.setState({isFullScreenBefore: true});
                    this.props.exitFullScreen();
                    PubSub.publish('ESC_FULL_SCREEN','false');
                }
                let msg2 = {
                    RewardEventType : '3',
                    data : 'empty',
                };
                console.log('show video ads msg', msg2, msg);
                msg2 = JSON.stringify(msg2);
                PubSub.publish('VIDEO_ADS_COMPLETED', msg2);
                if(this.props.browserName == 'Safari' && this.state.isFullScreenBefore) {
                    setTimeout(() => {
                        this.state.event.makeRewardedVisible();
                    },1000);
                }
                else {
                    this.state.event.makeRewardedVisible();
                }
            }
        });
        // this.state.event.makeRewardedVisible();
    }

    adsCommunicatorFunction() {
        console.log('videoads event called in react', event);
        let that = this;
        if(event.data) {
            let dataFromEvent = event.data;
            if(dataFromEvent && dataFromEvent !== undefined) {
                if(dataFromEvent.type=='videoads'){
                    const actionToDO = dataFromEvent.msg.action;
                    switch (actionToDO){
                        case 'ready':
                            console.log('Video ads available');
                            PubSub.publish('VIDEO_ADS_AVAILABLE', 'true');
                            this.showAdGsnCom();
                            break;
                        case 'close':
                            console.log('Video ads closed');
                            let msg = {
                                RewardEventType : '6',
                                data : JSON.stringify({placement_reward_amount : 1,placement_reward_name : that.state.placement, placement_name: that.state.placement}),
                            };
                            msg = JSON.stringify(msg);
                            if(that.state.isFullScreenBefore) {
                                const elemtoZoom = document.getElementById('gameContainer');
                                if (elemtoZoom && !document.mozFullScreen && !document.webkitFullScreen) {
                                    if (elemtoZoom.mozRequestFullScreen) {
                                        elemtoZoom.mozRequestFullScreen();
                                    } else {
                                        elemtoZoom.webkitRequestFullScreen(Element.ALLOW_KEYBOARD_INPUT);
                                    }
                                }
                                PubSub.publish('ESC_FULL_SCREEN','false');
                            }
                            console.log("video ads", msg);
                            PubSub.publish('VIDEO_ADS_COMPLETED', msg);
                            break;
                        case 'complete':
                            console.log('Video ads completed');
                            console.log("Reward Video Add completed");
                            let msg2 = {
                                RewardEventType : '2',
                                data : 'empty'
                            };
                            msg2 = JSON.stringify(msg2);
                            PubSub.publish('VIDEO_ADS_COMPLETED', msg2);
                            break;
                        case 'refreshTokens':
                            console.log('refreshTokens');
                            PubSub.publish('ADD_TOKENS_METHOD', 'true');
                            break;
                    }
                }
            }
        }

    }

    getAdFormat() {
        
    }


    componentWillUnmount() {
        console.log('componentWillUnmount called');
        PubSub.unsubscribe('VIDEO_ADS_AVAILABLE');
        PubSub.unsubscribe('PLAY_VIDEO_ADS');
        PubSub.unsubscribe('VIDEO_ADS_COMPLETED');
        PubSub.unsubscribe('initializeAds');
        PubSub.unsubscribe('REFRESH_ADS');
        window.removeEventListener('message', this.adsCommunicatorFunction);
    }



    render() {
        return (
            <div id='div-gpt-ad-1706609394679-0'></div>
        );
    }
}
