//@flow
import * as React from 'react';
import css from './style.css';
import GdprService from '../../services/gdprService';
import analyticsManagerInstance from '../../managers/analyticsManager';
import analyticslog from '../../constants/analyticslog';
import {PLATFORM_GSNCOM} from '../../constants/matcher';
import letsgobuuton from '../Lobby/images/Green_Button.png';
import closebuuton from '../Lobby/images/close_btn.png';
import PubSub from "pubsub-js";

type PropsT = {
    st: any,
    pparam: any,
    oparam: func,
};
type StateT = {};
export default class Redirection extends React.Component<PropsT, StateT> {
    constructor(props) {
        super(props);
        this.state = {
            OptinResponse: props.OptinResponse,
            fbUserId: props.fbUserId,
            SeamUser: props.SeamUser,
        };
        this.redirectUser = this.redirectUser.bind(this);
        this.backToLobby = this.backToLobby.bind(this);
    }
    componentDidMount() {}
    async redirectUser() {
        const pparam = this.props.pparam;
        const sessionToken = this.props.st;
        const oparam = this.props.oparam;
        let url = process.env.Classic_Casino_Url_payment+ 'session=' + sessionToken + '&oid=' + oparam + '&pid=' + pparam;
        window.open(url, "_top");
        // window.location.href = url;
    }
    async backToLobby() {
        PubSub.publish('CLOSE_PAYMENT_REDIRECTION_POPUP', true);
    }
    render() {
        const buttonStyle = css['GDPR-sprite-sheet-btn-yes-active'];
        return (
            <div
                id="gdpr-model-popup"
                className={`${css['GDPR-sprite-sheet-popup']} ${css['gdpr-model-popup']}`}
            >
                <a
                    className={`${buttonStyle} `}
                    id="buttonClass"
                    onClick={this.backToLobby}
                    style={{position: 'absolute', backgroundSize: '100% 100%', top:'-10px' , right: '-15px', width: '50px', height: '50px', backgroundImage: `url(${closebuuton})`}}
                />
                <button className={`${css.buttonClass}`} onClick={this.redirectUser}></button>
            </div>
        );
    }
}
