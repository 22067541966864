import { connect } from 'react-redux';
import Redirection from './Redirection';
import { closeModal } from '../../store/app/actions';

const mapStateToProps = () => ({});

const mapDispatchToProps = { closeModal };

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Redirection);
