'use strict';
let accessfbToken;
export default function loginToFacebook(appId, cb) {
    if (!window.FB) cb('FB Track loginToFacebook() FB object not defined', null);

    var loginParams = {};
    loginParams.scope = 'public_profile, email';
    loginParams.return_scopes = true;
    loginParams.display = 'popup';

    window.FB.getLoginStatus(function(response) {
        if(response.status === "connected"){
            let userID = response["authResponse"]["userID"];
            console.info("FB Track loginToFacebook() authorized app login(): userId", userID);
            accessfbToken = response["authResponse"]["accessToken"];
            cb(null, response);
        }else{
            window.FB.login(function(response) { //New Implementation
                console.log("login resposne", response);
                if (response.status === 'connected') {
                    let userID = response["authResponse"]["userID"];
                    console.info("FB Track loginToFacebook() authorized app login(): userId", userID);
                    accessfbToken = response["authResponse"]["accessToken"];
                    cb(null, response);
                } else {
                    console.error('FB Track loginToFacebook() user cancelled login or did not fully authorize!!', response);
                    alert("Facebook account can't log in");
                    cb(null, response);
                }
            }, {scope: 'public_profile,email,user_location',auth_type: 'rerequest' ,return_scopes: true });
        }
    });
}

/*window.FB.getLoginStatus(function(response) {


    /!*if(response.status === "connected"){
        let userID = response["authResponse"]["userID"];
        console.info("FB Track loginToFacebook() authorized app login(): userId", userID);
        accessfbToken = response["authResponse"]["accessToken"];
        cb(null, response);
    }else{
        window.FB.login(function(response) { //New Implementation
            console.log("login resposne", response);
            if (response.status === 'connected') {
                let userID = response["authResponse"]["userID"];
                console.info("FB Track loginToFacebook() authorized app login(): userId", userID);
                accessfbToken = response["authResponse"]["accessToken"];
                cb(null, response);
            } else {
                console.error('FB Track loginToFacebook() user cancelled login or did not fully authorize!!', response);
                alert("Facebook account can't log in");
                cb(null, response);
            }
        }, {scope: 'public_profile,email,user_location',auth_type: 'rerequest' ,return_scopes: true });
    }*!/
});
}*/

// Facebook's UI API returns a single response for both success and failure
// Its up to the caller to handle this as part of a successful response.
// Put a try / catch to account for any config issues.
export function facebookUIWithParams(params, cb) {
    if (!window.FB) cb('FB Track facebookUIWithParams() FB object not defined', null);
    else {
        window.FB.ui(params, (response) => {
            cb(response);
        });
    }
}
export function getFacebookUserProfile(cb) {
    if (!window.FB) cb('FB Track getFacebookUserProfile() FB object not defined', null);
    else {
        console.info("FB Track getFacebookUserProfile() with last login access Token = ", accessfbToken);
        window.FB.api('/me?access_token='+accessfbToken, { fields: 'name,email,picture,location' }, (response) => {
            console.info("FB Track getFacebookUserProfile() response = ", response["id"]);
            cb(null, response);
        });
    }
}
